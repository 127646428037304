import Vue from 'vue';
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'Home',
        component: () => import( /* webpackChunkName: "page" */ '@/views/MstHome/MstHome.vue'),
    },
    {
        path: '/Product',
        name: 'Product',
        component: () => import( /* webpackChunkName: "page" */ '@/views/MstProduct/MstProduct.vue'),
    },
	{
	    path: '/Badge',
	    name: 'Badge',
	    component: () => import( /* webpackChunkName: "page" */ '@/views/MstWorkCard/MstWorkCard.vue'),
	},
    {
        path: '/MstAboutUs',
        name: 'AboutUs',
        component: () => import( /* webpackChunkName: "page" */ '@/views/MstAboutUs/MstAboutUs.vue'),
    },
    {
        path: '/SubmittedSuccessfully',
        name: 'SubmittedSuccessfully',
        component: () => import( /* webpackChunkName: "page" */ '@/views/SubmittedSuccessfully/SubmittedSuccessfully.vue'),
        meta: {
            aaa: true
        }
    },
    // {
    //     path: '/Aboutus',
    //     name: 'Aboutus',
    //     component: () => import('../views/Aboutus/index'),
    //     meta: {
    //         keepAlive: true // 需要被缓存
    //     }
    // },
    {
        path: "*",
        redirect: "/"
    },
]

const router = new VueRouter({
    mode: 'hash',
    // base: process.env.BASE_URL,
    routes
})
router.afterEach((to, from) => {
    if (to.name == "selectedItems" && (from.name == 'libraryGoods' || from.name == 'sensorDetails')) { //从后面两个页面跳到items页面需要打开缓存
        to.meta.keepAlive = true;
    } else if (from.name == 'selectedItems' && (to.name == 'Home' || to.name == 'sensorDetails')) { //从items返回到后面两个页面取消缓存，或者不用该判断，在后面两个路由的beforEnter钩子中实现
        from.meta.keepAlive = false;
    }
})

export default router