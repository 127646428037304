import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

import 'normalize.css'
import 'amfe-flexible/index.js'

import router from './router';
import "./styles/main.scss"
import "../rem.js"

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
